
import { defineComponent, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    ErrorMessage,
    Form
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const version = process.env.VUE_APP_ZMS_APP_VERSION;
    const mainTitle = process.env.VUE_APP_MAIN_TITLE;
    const copyRight = process.env.VUE_APP_COPY_RIGTH;

    const { executeRecaptcha, recaptchaLoaded }: any = useReCaptcha();

    const submitButton = ref<HTMLElement | null>(null);

    store.dispatch(Actions.LOGOUT);

    const login = Yup.object().shape({
      username: Yup.string().required("Хэрэглэгчийн нэр хоосон байна."),
      password: Yup.string()
        .min(4, "Нууц үг 4 тэмдэгдээс багагүй байна.")
        .required("Нууц үг хоосон байна.")
    });

    //Form submit function
    const onSubmitLogin = async values => {
      await store.dispatch(Actions.LOGOUT);
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      await recaptchaLoaded()
        .then(async () => {
          await executeRecaptcha("login")
            .then(token => {
              const recaptchaToken = { recaptcha: token };
              const payload = Object.assign(values, recaptchaToken);
              store.dispatch(Actions.LOGIN, payload).then(
                () => {
                  store.dispatch(Actions.SIGNIN).then(() => {
                    submitButton.value?.removeAttribute("data-kt-indicator");
                    router.push({ name: "reference" });
                  });
                },
                error => {
                  submitButton.value?.removeAttribute("data-kt-indicator");
                  if (error === 500) {
                    Swal.fire({
                      text: "Нэвтрэх үед сервер талын алдаа гарлаа.",
                      icon: "warning",
                      buttonsStyling: false,
                      confirmButtonText: "Дахин оролдоно уу!",
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-warning"
                      }
                    });
                  } else {
                    Swal.fire({
                      text: error,
                      icon: "warning",
                      buttonsStyling: false,
                      confirmButtonText: "Дахин оролдоно уу!",
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-warning"
                      }
                    });
                  }
                }
              );
            })
            .catch(() => {
              Swal.fire({
                text: "Баталгаажуулалт амжилтгүй.",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Дахин оролдоно уу!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-warning"
                }
              });
            });
        })
        .catch(() => {
          Swal.fire({
            text: "Баталгаажуулалт амжилтгүй.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Дахин оролдоно уу!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-warning"
            }
          });
        });
    };
    return {
      onSubmitLogin,
      login,
      submitButton,
      mainTitle,
      copyRight,
      version
    };
  },
  methods: {}
});
